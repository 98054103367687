import React from 'react';
import {observer} from 'mobx-react';
import {Form, Input, Select, Button, Radio, DatePicker, Row, Col, Modal} from 'antd';
import http from 'libs/http';
import history from 'libs/history'
import store from './store';
import ClassStore from '../../student/class/store';
import styles from './student.module.css';
import wx from '../../../layout/mg-wx.png';
import _ from 'lodash'

@observer
class ComForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      counselor_info: ['豌豆', '云豆', '红豆', '土豆', '灵豆', '憨豆', '仙豆', '嗨豆', 'Miss李', '云珍', '薇薇', '景会', '清豆','甜豆','悦豆','糖豆','蚕豆','婷婷','银豆','蜜豆','明月','晓豆','可可豆','小彩豆','彩虹豆','王露露','福豆','关关','珈仪'],
      know_by_info: ['百度搜索', '马哥官网', '知乎', '微信群', 'QQ群', '微信朋友圈', '朋友推荐', '腾讯课堂', '网易云课堂'],
      know: null,
    }
  }

  componentDidMount() {
    if (ClassStore.records.length === 0) {
      ClassStore.fetchRecords()
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        this.setState({loading: true});
        values['pay_time'] = values['pay_time'].format('YYYY-MM-DD');
        http.post('/api/student/add/', values)
          .then(res => {
            history.push('/student/result');
          }, () => this.setState({loading: false}))
      }
    });
  };
  handleClassType = e => {
    store.st_class_name = _.filter(ClassStore.records, {'type': e});
  };
  handleAddKnow = () => {
    Modal.confirm({
      icon: 'exclamation-circle',
      title: '添加获知课程途径',
      content: this.addKnowForm,
      onOk: () => {
        if (this.state.know) {
          store.know.push(this.state.type);
          this.props.form.setFieldsValue({'know_by': this.state.know})
        }
      },
    })
  };

  addKnowForm = (
    <Form>
      <Form.Item required label="获知课程途径">
        <Input onChange={val => this.setState({know: val.target.value})}/>
      </Form.Item>
    </Form>
  );

  render() {
    const info = store.record;
    const {getFieldDecorator} = this.props.form;
    const itemLayout = {
      labelCol: {span: 8},
      wrapperCol: {span: 12}
    };
    return (
      <div className={styles.container}>
        <span className={styles.title}>马哥教育学员报名信息登记表</span>
        <div className={styles.content}>
          <Form>
            <Row>
              <Col span={12}>
                <Form.Item {...itemLayout} required label="培训课程">
                  {getFieldDecorator('at_class_name', {initialValue: info['at_class_name']})(
                    <Select placeholder="请选择培训课程" showSearch onSelect={this.handleClassType}>
                      {ClassStore.type.map(item => (
                        <Select.Option key={item} value={item}>{item}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="姓名">
                  {getFieldDecorator('name', {initialValue: info['name']})(
                    <Input placeholder="因课程授权需要绑定身份证,请务必提供与身份证一致得真实姓名"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="年龄">
                  {getFieldDecorator('age', {initialValue: info['age']})(
                    <Input placeholder="请输入您的年龄"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="所在地">
                  {getFieldDecorator('address', {initialValue: info['address']})(
                    <Input placeholder="请输入所在地"/>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="目前年薪">
                  {getFieldDecorator('salary_now', {initialValue: info['salary_now']})(
                    <Input placeholder="请输入目前年薪"/>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="毕业学校">
                  {getFieldDecorator('school', {initialValue: info['school']})(
                    <Input placeholder="请输入毕业学校"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="QQ">
                  {getFieldDecorator('qq', {initialValue: info['qq']})(
                    <Input placeholder="请输入QQ"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="课程顾问">
                  {getFieldDecorator('counselor', {initialValue: info['counselor']})(
                    <Select placeholder="请选择课程顾问" allowClear>
                      {this.state.counselor_info.map(item => (
                        <Select.Option key={item} value={item}>{item}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="支付时间">
                  {getFieldDecorator('pay_time',
                    {rules: [{type: 'object', required: true, message: '请选择支付时间!'}]},
                    {initialValue: info['pay_time']})(<DatePicker/>)}
                </Form.Item>
                <Form.Item {...itemLayout} required label="邮箱地址">
                  {getFieldDecorator('email_address', {initialValue: info['email_address']})(
                      <Input placeholder="邮箱地址"/>
                  )}
                </Form.Item>
                <Form.Item>
                  <div className={styles.notes}>
                    <span><b>【特别说明】</b><br/></span>
                    <span>
                    1、所有内容需要认真填写，切勿错误；<br/>
                    2、有疑问的地方请咨询课程顾问；<br/>
                    3、支付定金属于占用学校座位预留资格和预习视频的费用，缴纳后不再退还；<br/>
                    4、学习费用可开具发票，学费不含税点，需另外支付税点，发票开具有效期是从报名日起3个月内，请知悉；<br/>
                    5、因课程授权需要绑定身份证,请务必提供与身份证一致得真实姓名！
                  </span>
                  </div>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item {...itemLayout} required label="课程期数">
                  {getFieldDecorator('at_class_id', {initialValue: info['at_class_id']})(
                    <Select placeholder="请选择课程期数" showSearch>
                      {store.st_class_name.map(item => (
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="性别">
                  {getFieldDecorator('sex', {initialValue: info['sex']})(
                    <Radio.Group>
                      <Radio value="男">男</Radio>
                      <Radio value="女">女</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="学历">
                  {getFieldDecorator('education', {initialValue: info['education']})(
                    <Input placeholder="请输入学历"/>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="目前岗位">
                  {getFieldDecorator('job', {initialValue: info['job']})(
                    <Input placeholder="请输入目前岗位"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="期望年薪">
                  {getFieldDecorator('salary_expect', {initialValue: info['salary_expect']})(
                    <Input placeholder="请输入期望年薪"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="手机">
                  {getFieldDecorator('mobile', {initialValue: info['mobile']})(
                    <Input placeholder="请输入手机号"/>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="选择马哥教育的理由？">
                  {getFieldDecorator('join_reason', {initialValue: info['join_reason']})(
                    <Input.TextArea placeholder="请输入马哥教育的理由"/>
                  )}
                </Form.Item>

                <Form.Item {...itemLayout} required label="获知课程途径">
                  <Col span={16}>
                    {getFieldDecorator('know_by', {initialValue: info['know_by']})(
                      <Select placeholder="请选择获取途径" allowClear>
                        {this.state.know_by_info.map(item => (
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        ))}
                      </Select>
                    )}
                  </Col>
                  <Col span={6} offset={2}>
                    <Button type="link" onClick={this.handleAddKnow}>其他途径</Button>
                  </Col>
                </Form.Item>
                <Form.Item {...itemLayout} label="支付渠道">
                  {getFieldDecorator('pay_channel', {initialValue: info['pay_channel']})(
                    <Input placeholder="请输入支付渠道"/>
                  )}
                </Form.Item>
                <Form.Item {...itemLayout} required label="收件地址">
                  {getFieldDecorator('delivery_address', {initialValue: info['delivery_address']})(
                      <Input.TextArea placeholder="收件地址"/>
                  )}
                </Form.Item>
                <Form.Item>
                  <div >
                    <img height="250px" width="250px" src={wx} alt="weixin" />
                    <br/>
                    <span>关注微信公众号，获取最新Linux和Python技术干货</span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div style={{ marginBottom: "20px"}}>
          <Button type="primary" loading={this.state.loading} onClick={this.handleSubmit}>确认无误，提交</Button>
        </div>
      </div>


    )
  }
}

export default Form.create()(ComForm)
