import { makeRoute } from "../../libs/router";
import Class from './class';
import Info from './info';
import Work from './work';
import Coin from './coin';
import Express from './express';


export default [
  makeRoute('/class', Class),
  makeRoute('/info', Info),
  makeRoute('/work', Work),
  makeRoute('/coin', Coin),
  makeRoute('/express', Express),
]
